@import url('https://fonts.googleapis.com/css2?family=Rosario&display=swap');


.aboutSection {
    /* min-height: 80vh; */
    width: 100%;
    display: flex;
    margin-top: 100px;
    align-items: center;
    justify-content: center;
}

.about-intro {
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    animation-name: about-page-animation;
    animation-duration: 2s;
}


@keyframes about-page-animation {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.about-intro-image {
    float: left;
    width: 50%;
}

.about-intro-data {
    float: left;
    border-radius: 4px;
    /* background-color: #f7f7f7; */
    font-family: 'Rosario';
    color: rgb(2, 40, 71);
    font-weight: bolder;
    font-size: 1.8rem;
    line-height: 1.8;
    width: 50%;
    align-items: center;
    justify-content: center;
    /* padding: 40px; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */

}

.about-name {
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'ivymode';
    color: rgb(2, 40, 71);
}

@media screen and (min-width: 1200px) {
    .mobile-about-intro {
        display: none;
    }

    .about-intro {
        display: block;
    }
}

@media screen and (max-width: 1200px) {
    .about-intro {
        display: none !important;
    }


    .mobile-about-intro {
        justify-content: center;
        align-items: center;
    }

    .about-intro-image-mobile {
        justify-content: center;
        margin-left: 20px;
    }

    .about-intro-data-mobile {
        margin: 20px !important;
        color: rgb(2, 40, 71);
        font-size: 2.8vw !important;
    }

    .about-name-mobile {
        font-size: 5vw;
        font-weight: bold;
        font-family: 'ivymode';
        color: rgb(2, 40, 71);
    }
}