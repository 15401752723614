.nav-header-one {
  max-height: 12vh;
}

.topNameHeader {
  background-color: #83afc8;
}

.navBarContainerTopDiv {
  border-top: 2px solid black !important;
  border-bottom: 2px solid black !important;
  box-shadow: 0px 2px 8px black !important;
}

.topNavContainer {
  width: 100% !important;
}

.mobileNavBarContainer {
  display: none;
}

.navBarContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
}

.webNav {
  text-align: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.navBar {
  background-color: #5490b3;
  padding: 0px !important;
}

.navLink {
  font-size: 0.9vw;
  color: rgb(63, 63, 63) !important;
  font-weight: bold !important;
  text-decoration: none;
  padding-left: 20vw;
  padding-right: 20vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  transition: all 0.45s ease-out;
}

.navLink:hover {
  transform: scale(1.1);
  transition: transform 0.45s ease-in-out;
}

.navLinkActive {
  font-weight: bold !important;
  font-size: 1.1vw !important;
  border: 2px solid rgba(4, 4, 13, 0.356) !important;
  background-color: rgba(227, 227, 227, 0.425);
  color: black !important;
  border-radius: 10px !important;
}

@media screen and (min-width: 501px) and (max-width: 1100px) {

  /* .navBarContainer {
    margin-right: 50px !important;
    margin-left: 50px !important;
  } */

  .topNavContainer {
    width: 100% !important;
    margin: 0 !important;
  }

  .navLink {
    font-size: 0.75em !important;
  }

  .nav-header-one {
    max-height: 10vh;
  }

  .basic-navbar-nav {
    font-size: small;
  }

  .navbar-toggler.collapsed {
    padding: 5px !important;
  }

  .navbar-toggler-icon {
    width: 5vw !important;
    height: 5vw !important;
  }

  .navLinkActive {
    font-size: 1em !important;
    background-color: transparent !important;
    border: none !important;
    color: black !important;
    font-weight: bold !important;
  }

  .navBarContainer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}


@media screen and (min-width: 601px) {
  .navBarMobile {
    display: none;
  }
}

/*Mobile*/
@media screen and (max-width: 600px) {

  .navBarMobile {
    display: inline;
    margin: 0;
  }


  .topNavContainer {
    display: none;
  }

  .nav-header-one {
    max-width: 280px;
  }

  .navLink {
    font-size: 1.1rem !important;
  }

  #dropdown-basic-button {
    background-color: #365161 !important;
  }

  .basic-navbar-nav {
    font-size: small;
  }

  .navbar-toggler.collapsed {
    padding: 5px !important;
  }

  .navbar-toggler-icon {
    width: 5vw !important;
    height: 5vw !important;
  }

  .navLinkActive {
    font-size: 1.2rem !important;
    background-color: transparent !important;
    border: none !important;
    color: black !important;
    font-weight: bold !important;
  }

  .navBarContainer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1500px) {
  .navLink {
    font-size: 1.2rem !important;
  }

  .navLinkActive {
    font-size: 1.3rem !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1450px) {
  .navLink {
    font-size: small;
  }
}


@media screen and (min-height: 1200px) {
  .nav-header-one {
    max-height: 130px;
  }

}

@import url("//fonts.googleapis.com/css?family=Pacifico&text=Pure");
@import url("//fonts.googleapis.com/css?family=Roboto:700&text=css");
@import url("//fonts.googleapis.com/css?family=Kaushan+Script&text=!");