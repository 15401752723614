@import url("https://fonts.googleapis.com/css2?family=Rosario&display=swap");

.homeSection {
  padding-top: 40px;
  min-height: fit-content;
  width: 100%;
}

.home-intro {
  width: 100%;
  margin: 0 auto;
  animation-name: home-page-animation;
  animation-duration: 1s;
}

.home-intro-image {
  width: 65%;
  height: 700px;
  background: url("https://static1.squarespace.com/static/61a5a13d993ee055dcef10e4/t/61cf4e1966f47c28e897b65c/1640975907519/react_cover.jpg") no-repeat center/cover;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  /* animation-name: contact-image-animation;
    animation-duration: 2s; */
}

@keyframes home-page-animation {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.home-intro-data {
  border-radius: 4px;
  /* color: white; */
  /* background-color: #155e83; */
  width: 50%;
  margin-left: -10vw;
  font-family: 'Rosario';
  color: rgb(2, 40, 71);
  font-weight: bolder;
  font-size: 1.2vw;
  line-height: 1.4;
  align-items: left;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  margin-top: 5.5vh;
  margin-right: 20vw;
  vertical-align: middle;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
}

.thumbImage {
  margin: 10px;
  max-width: 7vw;
  max-height: 15vh;
  border: none;
  width: auto;
  height: auto;
  vertical-align: middle;
  border-radius: 5px;
  border: 1px solid black;
  transition: all 0.45s ease-out;
}

.homeLink:hover {
  cursor: none
}


.home-other {
  /* margin-: 20px; */
  display: flex;
}

.home-links {
  justify-content: center;
  text-align: center;
  float: left;
  width: 50%;
  margin-top: 50px;
}

#home-btn {
  display: block;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1vw;
  cursor: pointer;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

#home-btn:hover {
  color: white;
  transform: scale(1.1);
}

.thumbImage:hover {
  transform: scale(1.1);
  box-shadow: 2px 2px #0c2230;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.home-montage-image img {
  margin-top: -1.6%;
  background-position: top center;
  width: 100%;
}

/* if screen size small */
@media screen and (max-width: 759px) {
  .home-intro-data {
    font-size: 3vw;
    width: 100%;
    margin-top: 2%;
    margin-left: 0;
    margin-right: 0%;
  }

  .home-montage-image img {
    display: none;
  }

  .thumbImage {
    margin-top: 0px;
    max-width: 20vw !important;
    max-height: 25vh !important;
  }

  #home-btn {
    font-size: 2.5vw;
  }

  .homeSection {
    padding-top: 30px;
    min-height: 50vh;
  }
}


/* if screen size small */
@media screen and (min-width: 760px) and (max-width: 1200px) {
  .home-intro-data {
    font-size: 2.3vw;
    width: 100%;
    margin-top: 4%;
    margin-left: 0;
    margin-right: 1.5%;
  }

  /* .home-montage-image img {
    display: none;
  } */

  .thumbImage {
    margin-top: 2vh;
    max-width: 16vw !important;
    max-height: 19vw !important;
  }

  #home-btn {
    font-size: 1.5vw;
  }

  .homeSection {
    padding-top: 30px;
    min-height: 50vh;
  }
}