.newWorkContainer {
  padding-top: 35px;
  display: flex;
  text-align: center;
  animation-name: gallery-page-animation;
  animation-duration: 2s;
}

@keyframes gallery-page-animation {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.mainNewWorkContainer {
  display: none;
}

/*This is for icons of related images*/
.related_images_thumb {
  max-width: 7vw;
  max-height: 15vh;
  border: 3px solid #143344;
  margin: 15px;
  border-radius: 5px;
  transition: all 0.45s ease-out;
}



@media screen and (min-width: 600px) {
  .gallery_mobile {
    display: none;
  }
}

/*Mobile Design*/
@media screen and (max-width: 500px) {
  .mainNewWorkContainer {
    display: none !important;
  }

  #display_image {
    display: none;
  }

  .related_images_thumb {
    display: none;
  }

  #fullScreenIcon {
    display: none;
  }

  #display_image_information {
    display: none;
  }

  .gallery_mobile {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }

  /* Create two equal columns that sits next to each other */
  .mobileColumn {
    flex: 20%;
    padding: 0 4px;
  }

  .mobileColumn img {
    width: auto;
    height: auto;
    max-width: 45vw !important;
    max-height: fit-content;
    padding: 3px;
    border-radius: 4px;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    margin-top: 8px;
    vertical-align: middle;
  }
}