.galleryFourContainer {
  padding-top: 30px;
  display: flex;
  text-align: center;
  animation-name: gallery-page-animation;
  animation-duration: 2s;
}

@keyframes gallery-page-animation {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.mainGalleryFourContainer {
  display: none;
}


@media only screen and (min-width: 1320px) and (max-width: 1680px) {
  .row img {
    margin: 10px;
    max-width: 240px;
    max-height: 180px;
    border-radius: 4px;
    border: 2px solid black;
    background-color: white;
    width: auto;
    height: auto;
    padding: 3px;
    transition: all 0.2s ease-in-out;
    vertical-align: middle;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1320px) {
  .row img {
    margin: 10px;
    max-width: 200px;
    border-radius: 4px;
    max-height: 150px;
    border: 2px solid black;
    background-color: white;
    width: auto;
    height: auto;
    padding: 3px;
    transition: all 0.2s ease-in-out;
    vertical-align: middle;
  }
}

@media screen and (max-height: 1050px) {
  /* #display_image {
      max-width: 900px;
      max-height: 780px;
    } */
}

@media screen and (min-height: 1150px) and (max-height: 1320px) {
  /* #display_image {
      max-width: 900px;
      max-height: 800px;
    } */
}

@media screen and (min-width: 800px) {
  .gallery_mobile {
    display: none;
  }
}

/*Mobile Design*/
@media screen and (max-width: 500px) {
  .mainGalleryFourContainer {
    display: none !important;
  }

  #display_image {
    display: none;
  }

  .related_images_thumb {
    display: none;
  }

  #fullScreenIcon {
    display: none;
  }

  #display_image_information {
    display: none;
  }

  .gallery_mobile {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }

  /* Create two equal columns that sits next to each other */
  .mobileColumn {
    flex: 25%;
    padding: 0 4px;
  }

  .mobileColumn img {
    width: auto;
    height: auto;
    max-width: 45vw !important;
    max-height: fit-content;
    padding: 3px;
    border-radius: 4px;
    border: 2px solid black;
    background-color: rgb(255, 255, 255);
    margin-top: 8px;
    vertical-align: middle;
  }
}