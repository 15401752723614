@import url('https://fonts.googleapis.com/css2?family=Rosario&display=swap');


.contactSection {
    padding-top: 40px;
    /* min-height: 80vh; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: contact-page-animation;
    animation-duration: 2s;
}

.contact-image {
    width: 50%;
    height: 700px;
    border-radius: 5px;
}

@keyframes contact-page-animation {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.contact-data {
    border-radius: 4px;
    font-family: 'Rosario';
    color: rgb(2, 40, 71);
    font-size: 1.5rem;
    line-height: 1.8;
    width: 50%;
    margin-left: 50px;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

#contact-title-tag {
    font-size: 2.3rem;
    color: rgb(2, 40, 71);

    font-family: 'Rosario';
    font-weight: bold;
    margin-bottom: 20px;
}

#phone-icon,
#email-icon {
    padding-right: 5px;
}


#contact-info-a {
    color: rgb(2, 40, 71);
    font-size: x-large;
}

.cimage {
    margin-top: 30px;
    max-width: 800px;
    max-height: 650px;
    border: 2px solid black;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 1200px) {
    .contact-container-mobile {
        display: none;
    }

}

@media screen and (max-width: 1200px) {
    .contact-container {
        display: none !important;
    }

    .contact-container-mobile {
        display: block !important;
        /*center this div*/
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .cimage-mobile {
        max-width: 40vw;
        max-height: 40vh;
        border: 2px solid black;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .contact-data-mobile {
        margin: 20px !important;
        font-size: 0.7rem !important;
        line-height: 1.2 !important;
        color: rgb(2, 40, 71);
    }



    #contact-title-tag-mobile {
        font-family: 'Rosario' !important;
        font-size: 1.6rem !important;
        /*make it bold*/
        font-weight: bold !important;
        padding: 20px;
    }

    #h4-name-mobile {
        font-family: 'Rosario' !important;
    }
}