/*This file will contain all the generic components*/


/*This shows up so the images can render before hand and user can interact with ease*/
.loadingProgressBar {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*This button is used to open the image in full size*/
#fullScreenIcon {
  border-radius: 15%;
  color: black(255, 255, 255);
  padding: 5px;
  margin: 10px;
  border: 2px solid black;
  transition: transform 0.45s ease-in-out;
}

#fullScreenIcon:hover {
  cursor: pointer;
  transition: transform 0.45s ease-in-out;
  transform: scale(1.3);
}

/*This creates a flex box to show both iamge and related*/
.displayImageContainer {
  display: flex;
  margin-right: 20px;
  text-align: center;
  align-items: center;
}

.displayImageOneFlex {
  display: flex;
}

.displayImageNonFlex {
  display: block !important;
  width: 80%;
  text-align: center;
  align-items: center;
}

.displayImageOne {
  float: left;
  width: 90%;
}


.firstContainer {
  width: 33%;
  float: left;
  text-align: center;
  align-items: center;
  padding: 20px;
}

.secondContainer {
  width: 67%;
  float: right;
  text-align: center;
  padding: 10px;
}

/* Create two equal rows that sits next to each other */
.row,
.row-small,
.row-xSmall {
  flex: 50%;
  padding: 4px;
  margin: 0px !important;
  align-items: center;
  justify-content: center;
}


/*Row Img is the Thumbnail Icon */
.row img {
  margin: 10px 8px 8px 8px;
  /* max-width: 190px;
  max-height: 180px; */
  max-width: 9vw;
  max-height: 18vh;
  width: auto;
  height: auto;
  padding: 2px;
  background-color: #143344;
  vertical-align: middle;
  border-radius: 4px;
  transition: all 0.45s ease-out;
}

/*This is custom size so declare seperate */
.row-small img {
  margin: 10px 8px 8px 8px;
  /* max-width: 180px;
  max-height: 170px; */
  max-width: 8vw;
  max-height: 17vh;
  width: auto;
  height: auto;
  padding: 2px;
  background-color: #143344;
  vertical-align: middle;
  border-radius: 4px;
  transition: all 0.45s ease-out;
}

.row-xSmall img {
  margin: 10px 8px 8px 8px;
  /* max-width: 180px;
  max-height: 170px; */
  max-width: 9vw;
  max-height: 18vh;
  width: auto;
  height: auto;
  padding: 2px;
  background-color: #143344;
  vertical-align: middle;
  border-radius: 4px;
  transition: all 0.45s ease-out;
}


.row-tiny img {
  margin: 10px 8px 8px 8px;
  /* max-width: 140px;
  max-height: 150px; */
  max-width: 7vw;
  max-height: 15vh;
  width: auto;
  height: auto;
  border: 2px solid black;
  vertical-align: middle;
  border-radius: 4px;
  transition: all 0.45s ease-out;
}

@media (hover: hover) and (pointer: fine) {

  .row img:hover,
  .row-small img:hover,
  .row-xSmall img:hover,
  .row-tiny img:hover {
    transform: scale(1.2);
    box-shadow: 1px 1px #7e9bac;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  .related_images_thumb:hover {
    transform: scale(1.2);
    box-shadow: 3px 3px rgb(188, 220, 238);
    cursor: pointer;
    transition: transform 0.45s ease-in-out;
  }
}

/*This part is to show the information about the sculpture*/
#display_image_information {
  color: rgba(0, 0, 0, 0.712);
  margin-top: 10px !important;
  padding: 10px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 5px;
}

#display_image_size,
#display_image_material {
  padding: 0px !important;
  font-weight: bold;
  margin: 0px !important;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

#display_image_information_border_less {
  color: white;
  margin-top: 10px !important;
  padding: 10px !important;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

/*This is the main display image*/
#display_image {
  margin-top: 20px;
  max-width: 50vw;
  max-height: 50vw;
  -webkit-box-shadow: 0 0 10px 3px black;
  -moz-box-shadow: 0 0 10px 3px black;
  box-shadow: 0 0 10px 3px black;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  transition: all 1s ease-out;
  border-radius: 4px;
}

#display_image:hover,
#display_image_landscape:hover {
  cursor: pointer;
  transform: scale(1.15);
  transition: transform 0.55s ease-in-out;
}

#display_image_landscape {
  max-width: 1300px;
  max-height: 100vh;
  -webkit-box-shadow: 0 0 10px 3px black;
  -moz-box-shadow: 0 0 10px 3px black;
  box-shadow: 0 0 10px 3px black;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  transition: all 1s ease-out;
  border-radius: 4px;
}

/*This is for icons of related images*/
.related_images_thumb {
  max-width: 7vw;
  max-height: 15vh;
  border: 3px solid #143344;
  margin: 12px;
  border-radius: 5px;
  transition: all 0.45s ease-out;
}




@media screen and (min-width: 760px) and (max-width: 1200px) {
  #display_image {
    max-width: 52vw;
    max-height: 65vw;
  }

  .row-tiny img,
  .row-small img,
  .row-xSmall img,
  .row img {
    max-width: 8vw;
    max-height: 10vh;
  }


  #display_image_heading {
    font-size: 2vw;
  }

  .firstContainer {
    width: 35%;
  }

  .secondContainer {
    width: 65%;
    margin-left: 2vw;
  }

  #display_image_information {
    margin-top: 10px !important;
    padding: 10px !important;

  }

  #display_image_size,
  #display_image_material {
    font-size: 1.5vw;
  }

  .displayImageContainer {
    margin-right: 0vw;
  }
}